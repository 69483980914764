import clsx from "clsx"
import { graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import AppraisalComponent from "../components/AppraisalComponent/AppraisalComponent"
import BannerLanding from "../components/BannerLanding/BannerLanding"
import FeatureProperties from "../components/FeatureProperties/FeatureProperties"
import Instagram from "../components/Instagram/Instagram"
import Layout from "../components/Layout/Layout"
import ReviewsSlider from "../components/ReviewsSlider/ReviewsSlider"
import SEO from "../components/SEO/seo"
import StatsComponent from "../components/StatsComponent/StatsComponent"
import TileBlockFull from "../components/TileBlockFull/TileBlockFull"
import HomePageSchema from "../components/Home/home-page-schema"
const HomeTemplate = ({ data }) => {
  const pageData = data?.strapiPage
  const modules = pageData?.add_page_modules
  const banner = pageData?.banner
  const [renderComponent, setRenderComponent] = useState(false)

  useEffect(() => {
    window.addEventListener("mousemove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("touchmove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("keypress", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("scroll", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
  }, [])
  return (
    <div className={clsx("home-template", pageData.page_class)}>
      <Layout
        popular_search={pageData?.select_popular_search}
        pageData={pageData}
      >
        <SEO
          title={
            pageData?.seo?.meta_title
              ? pageData?.seo?.meta_title
              : pageData?.title
          }
          description={
            pageData?.seo?.meta_description
              ? pageData?.seo?.meta_description
              : pageData?.title
          }
        />
        <HomePageSchema />
        <BannerLanding data={banner} homePage pageData={pageData} />
        {modules?.map((module, index) => {
          return (
            <>
              {module.__typename ===
                "STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE" &&
                module.select_module === "book_an_appraisal" && (
                  <AppraisalComponent />
                )}
              {module.__typename ===
                "STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE" &&
                module.select_module === "google_reviews" && <ReviewsSlider />}

              {module.__typename ===
                "STRAPI__COMPONENT_PAGE_MODULES_CONTENT_STATS" && (
                <StatsComponent module={module} homePage />
              )}
              {module.__typename ===
                "STRAPI__COMPONENT_PAGE_MODULES_TILE_BLOCK" &&
                module.tile_layout === "full_width" && (
                  <TileBlockFull module={module} pageData={pageData} />
                )}
              {module.__typename === "STRAPI__COMPONENT_PAGE_MODULES_SLIDER" &&
                module.choose_slider === "featured_properties" && (
                  <FeatureProperties module={module} />
                )}
              {module.__typename === "STRAPI__COMPONENT_PAGE_MODULES_SLIDER" &&
                module.choose_slider === "follow_us" &&
                renderComponent && <Instagram module={module} />}
            </>
          )
        })}
      </Layout>
    </div>
  )
}

export default HomeTemplate

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...PageFragment
      add_page_modules {
        ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
          __typename
          id
          select_module
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_CONTENT_STATS {
          ...StatFragment
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_TILE_BLOCK {
          ...TileBlockFragment
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_SLIDER {
          __typename
          id
          choose_slider
          heading
          title {
            data {
              title
            }
          }
          cta {
            ...CTAFragment
          }
        }
      }
    }
  }
`
