import React, { useState } from "react";
import { GoogleMap, useLoadScript, InfoWindow, Marker } from "@react-google-maps/api";
import { mapStyles } from "../constants"; 
import HTMLReactParser from 'html-react-parser';
import { Link } from "gatsby";
import _ from "lodash";
import activemap from "../../../images/icons/activemap-ch.svg"
import noactivemap from "../../../images/icons/noactivemap-ch.svg"
import popupimage from "../../../images/whitstable-map-img.jpg"
import "../styles/_index.scss";

const LocationMap = (props) => {
  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const areaLocations = [
    {id: 1, title: 'Whitstable', lat: 51.363888, lng: 1.042627, icon: activemap },
    {id: 2, title: 'Herne Bay', lat: 51.371274, lng: 1.126328, icon: noactivemap },
    {id: 3, title: 'Canterbury', lat: 51.279999, lng: 1.080000, icon: noactivemap },
    {id: 4, title: 'Faversham', lat: 51.315276, lng: 0.891801, icon: noactivemap }
  ];

  const center = {
    lat: 51.363888,
    lng: 1.042627,
  };

  const [currentInfo, setCurrentInfo] = useState(0);
  const { isLoaded } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: process.env.GATSBY_MAPS_API_KEY,
  });

  const onMarkerClick = (index) => {
    // setCurrentInfo(index === currentInfo ? null : index);
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={10}
      options={{
        styles: mapStyles,
        mapTypeControl: false,
        streetViewControl: false,
      }}
    >
      {areaLocations.map((location, index) => (
        <Marker
          key={index}
          position={{ lat: location.lat, lng: location.lng }}
          icon={location.icon}
          onClick={() => onMarkerClick(index)}
          marker={true}
        />
      ))}

      {areaLocations.map((location, index) => (
        currentInfo === index && (
          <InfoWindow
            key={index}
            position={{ lat: location.lat, lng: location.lng }}
            onCloseClick={() => setCurrentInfo(null)}
          >
            <div className="marker-tooltip-wrap">

              <div className="marker-image">
                <img
                  loading="lazy"
                  src={popupimage}
                  alt=""
                  width="100%"
                />
              </div>
              <div className="marker-info">
                <h6 className="h7">Christopher Hodgson Whitstable Estate Agents</h6>
                <div className="marker-address text-xs">
                  <p>95/97 Tankerton Road, Whitstable, Kent, CT5 2AJ</p>
                </div>
              </div>

            </div>
          </InfoWindow>
        )
      ))}
    </GoogleMap>
  ) : <></>;
};

export default React.memo(LocationMap);