import React, { useEffect } from "react"
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";

const HomePageSchema = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        // Remove existing JSON-LD script(s) only on the homepage
        if (typeof window !== "undefined" && pathname === "/") {
            const oldScripts = document.querySelectorAll('script[type="application/ld+json"]:not(#homepage-schema)');
            oldScripts.forEach((script) => script.remove());
        }
    }, [pathname]);

    const schemaJSON = {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": "WebPage", "@id": "https://www.christopherhodgson.co.uk/",
                "url": "https://www.christopherhodgson.co.uk/",
                "name": "Estate Agents in Whitstable | Letting Agents | Christopher Hodgson", "isPartOf":
                {
                    "@id": "https://www.christopherhodgson.co.uk/#website"
                },
                "about": { "@id": "https://www.christopherhodgson.co.uk/#organization" },
                "description": "Christopher Hodgson: Trusted Whitstable estate agents, covering both sales and lettings services across Whitstable & surrounding areas. Contact us today for more details."
            },
            {
                "@type": "WebSite", "@id": "https://www.christopherhodgson.co.uk/#website",
                "url": "https://www.christopherhodgson.co.uk/",
                "name": "Estate Agents in Whitstable | Letting Agents | Christopher Hodgson",
                "description": "Christopher Hodgson: Trusted Whitstable estate agents, covering both sales and lettings services across Whitstable & surrounding areas. Contact us today for more details.",
                "potentialAction":
                    [{
                        "@type": "SearchAction",
                        "target": {
                            "@type": "EntryPoint",
                            "urlTemplate": "https://www.christopherhodgson.co.uk?s={search_term_string}"
                        },
                        "query-input": "required name=search_term_string"
                    }],
                "inLanguage": "en-GB"
            },
            {
                "@type": "Organization", "@id": "https://www.christopherhodgson.co.uk/#organization",
                "name": "Christopher Hodgson",
                "sameAs": ["https://www.facebook.com/CHodgsonEA/", "https://twitter.com/CHodgsonEA", "https://www.instagram.com/chodgsonea/"]
            },
            {
                "@type": "RealEstateAgent",
                "@id": "https://www.christopherhodgson.co.uk/#RealEstateAgent",
                "url": "https://www.christopherhodgson.co.uk/",
                "name": "Estate Agents in Whitstable | Letting Agents | Christopher Hodgson",
                "description": "Christopher Hodgson: Trusted Whitstable estate agents, covering both sales and lettings services across Whitstable & surrounding areas. Contact us today for more details.",
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.9",
                    "bestRating": "5",
                    "worstRating": "1",
                    "reviewCount": "220"
                },
                "email": "info@christopherhodgson.co.uk",
                "telephone": "01227266441",
                "hasMap": "https://maps.app.goo.gl/4unaT4DybhzGaBxJ8",
                "address": {
                    "@type": "PostalAddress",
                    "streetAddress": "95-97 Tankerton Rd",
                    "addressLocality": "Whitstable",
                    "postalCode": "CT5 2AJ",
                    "addressCountry": "UK"
                },
                "openingHours":
                    [
                        "Mo-Fr 08:45am-05:30pm",
                        "Sa 09:00am-05:00pm",
                        "Sun Closed"
                    ],
                "location": {
                    "@type": "Place",
                    "geo": {
                        "@type": "GeoCoordinates",
                        "latitude": "51.36396",
                        "longitude": "1.04261"
                    }
                }
            }
        ]
    }

    const schema = JSON.stringify(schemaJSON);
    return (
        <>
            <Helmet>
                <script id="homepage-schema" type="application/ld+json">{schema}</script>
            </Helmet>
        </>
    );
}
export default HomePageSchema;